import React, { useEffect, useRef, useState } from 'react';
import ReactPixel from 'react-facebook-pixel';
import ReactPlayer from 'react-player';
import videojs from 'video.js';
import moment from 'moment';
import update from 'react-addons-update';
import { useDispatch, useSelector } from 'react-redux';
import { Helmet } from 'react-helmet';
import { useMediaQuery } from 'react-responsive';
import { Link } from 'react-router-dom';
import { Swiper, SwiperSlide } from 'swiper/react';

import CourseModel from '../../model/CourseModel';
import '../../assets/scss/page/viewer.scss';
import 'video.js/dist/video-js.css';
import {
  set_footer_type,
  set_header_type,
  set_tmp,
  stored_service,
} from '../../redux/common/action';
import { useObserver } from '../../util/BearuHooks';
import CourseNoticeModal from '../_common/dialog/CourseNoticeModal';
import { alert_and_redirect } from '../../common';
import { dialog_type_custom, useDialogContext } from '../_common/dialog/DialogContextProvider';
import { HEADER_TYPE_MOBILE_SUB, HEADER_TYPE_PC_VIEWER } from '../_common/section/Header';
import QnaContainer from '../_common/section/QnaContainer';

const Viewer = (props) => {
  //Modal
  const { showDialog, alert } = useDialogContext();

  //MediaQuery
  const is_tablet_or_mobile = useMediaQuery({ maxWidth: 765 });
  const is_landscape = useMediaQuery({ maxHeight: 500 }, { orientation: 'landscape' });
  const is_w550 = useMediaQuery({ query: '(max-width: 550px)' });

  //Redux
  const dispatch = useDispatch();
  const CommonStore = useSelector((state) => state.CommonStore);

  //State
  const [is_v2_player, setIsV2Player] = useState(CommonStore.service?.v2_player?.is_enabled);
  const [course, setCourse] = useState(null);
  const [subject_set, setSubjectSet] = useState([]);
  const [subject, setSubject] = useState(null);
  const [page, setPage] = useState(0);
  const [block, setBlock] = useState(0);
  const [total_page, setTotalPage] = useState([]);
  const [total_block, setTotalBlock] = useState(0);
  const [auto_play, setAutoPlay] = useState(CommonStore.tmp?.viewer_auto_play ?? true);
  const [is_sticky, setIsSticky] = useState(false);
  const [is_play, setIsPlay] = useState(false);
  const [is_failed, setIsFailed] = useState(false);
  const [state, setState] = useState({
    replay_hover: false,
    forward_hover: false,
  });
  const [_is_playing, setIsPlaying] = useState(false);
  const [is_buffer, setIsBuffer] = useState(false);
  const [visible_back_to_cur_time, setVisibleBackToCurTime] = useState(false);
  const [v2_player_ready, setV2PlayerReady] = useState(false);
  const [swiper_state, setSwiperState] = useState(null);
  const [active_slide_index, setActiveSlideIndex] = useState(null);
  const [folded_description, setFoldedDescription] = useState(null);

  let time_out;

  //Ref
  const player = useRef(null);
  const cur_time = useRef(0);
  const tmp_cur_time = useRef(null);
  const video_ref = useRef(null);
  const _is_pending = useRef(false);
  const _is_mount = useRef(false);
  const description_ref = useRef(null);
  const swiper_ref = useRef(null);
  const slide_1 = useRef(null);
  const slide_2 = useRef(null);
  const _is_video_ready = useRef(true);
  const _time_check_pending = useRef(false);

  //eslint-disable-next-line
  let new_exp_match = /(\b(http?|https):\/\/[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|])/gim;

  let params = new URLSearchParams(props.location.search);
  let q_subject_id = params.get('subject_id');
  let question_time = params.get('question_time');
  let prev_subject = params.get('prev_subject');
  let prev_cur_time = params.get('prev_cur_time');

  const subject_wrapper_ref = useObserver(
    {},
    () => {
      setIsSticky((is_sticky) =>
        update(is_sticky, {
          $set: false,
        }),
      );
    },
    () => {
      setIsSticky((is_sticky) =>
        update(is_sticky, {
          $set: true,
        }),
      );
    },
    [],
  );

  useEffect(() => {
    if (is_v2_player && player.current) {
      player.current.on('loadeddata', onAutoPlay);
      player.current.on('ended', onEnded);
    }
    return () => {
      if (is_v2_player && player.current) {
        try {
          player.current.off('loadeddata', onAutoPlay);
          player.current.off('ended', onEnded);
        } catch (e) {}
      }
    };
  }, [subject, auto_play, v2_player_ready]);

  useEffect(() => {
    if (course) {
      onSubject(q_subject_id);
    }
    if (is_tablet_or_mobile) {
      setFoldedDescription(true);
    }
  }, [q_subject_id]);

  //v2 플레이어 일 때 다른 강의로 시간 이동
  useEffect(() => {
    if (player.current && is_v2_player) {
      if (player.current.isReady_ && question_time) {
        setTimeout(() => {
          onSecChange(null, question_time);
        }, 50);
      } else if (question_time === null) {
        setVisibleBackToCurTime(false);
      }
    }
  }, [is_play, is_v2_player]);

  // 같은 강의 내에서 시간 이동 시
  useEffect(() => {
    if (question_time !== null && _is_video_ready.current) {
      onSecChange(null, question_time);
    }
  }, [question_time, prev_cur_time]);

  const onKeyDown = (e) => {
    if (e.keyCode === 32) {
      e.preventDefault();
      if (player.current) {
        if (is_v2_player) {
          if (player.current.paused()) {
            player.current.play();
          } else if (player.current.play()) {
            player.current.pause();
          }
        } else if (_is_video_ready.current) {
          setIsPlaying(!_is_playing);
        }
      }
    }
    if (e.keyCode === 37) onSecChange('prev');
    if (e.keyCode === 39) onSecChange('next');
  };

  let block_size = 5;
  let show = is_landscape ? 3 : 7;

  useEffect(() => {
    _is_mount.current = true;
    if (
      CommonStore.service?.v2_player?.is_enabled &&
      moment().diff(moment(CommonStore.service?.v2_player?.update_date), 'days') + 1 < 14
    ) {
      setIsV2Player(true);
    } else {
      setIsV2Player(false);
      dispatch(stored_service('v2_player', { is_enabled: false, update_date: new Date() }));
    }
    CourseModel.courseDetail({
      course_id: props.match.params.course_id,
      only_type: 'viewer_subject',
    }).then(({ code, course }) => {
      if (_is_mount.current) {
        if (code === 200) {
          setCourse(course);
          setSubjectSet(course.subject_set);
          setTotalPage(
            Array.from({ length: Math.ceil(course.subject_set.length / show) }, (_, i) => i),
          );
          setTotalBlock(Math.floor((Math.ceil(course.subject_set.length / show) - 1) / block_size));
          if (q_subject_id) {
            if (
              course?.subject_set.findIndex((data) => data.id === parseInt(q_subject_id)) === -1
            ) {
              alert_and_redirect(alert, props.history, '잘못된 접근입니다.');
              return;
            } else {
              onSubject(q_subject_id);
              setPage(
                parseInt(
                  course.subject_set.findIndex((data) => data.id === parseInt(q_subject_id)) / show,
                ),
              );
            }
          } else {
            if (
              !!CommonStore.tmp?.last_subject_set?.find(
                (data) => data.course_id === parseInt(props.match.params.course_id),
              )
            ) {
              props.history.replace(
                `/course/${course.id}/viewer?subject_id=${
                  CommonStore.tmp.last_subject_set.find((data) => data.course_id === course.id)
                    .subject_id
                }`,
              );
            } else {
              onSubject(course?.subject_set[0].id);
            }
          }

          if (
            course.notice &&
            !CommonStore.tmp?.read_notice_set?.find((data) => data === course.notice.id)
          ) {
            showDialog({
              type: dialog_type_custom,
              component: CourseNoticeModal,
              component_props: {
                content_title: course?.notice.title,
                content: course?.notice.content,
                set_never_show: () => {
                  dispatch(set_tmp('read_notice_set', [course.notice.id]));
                },
              },
            });
          }
        } else {
          alert_and_redirect(alert, props.history, '잘못된 접근입니다.');
        }
      }
    });

    document.body.onselectstart = () => true;
    document.body.ondragstart = () => true;

    return () => {
      document.body.onselectstart = () => false;
      document.body.ondragstart = () => false;
      if (is_v2_player && player.current) {
        try {
          player.current.off('error');
        } catch (e) {}
      }
      _is_mount.current = false;
    };
  }, []);

  //v2 전용 자동 재생
  const onAutoPlay = () => {
    if (auto_play && player.current) {
      player.current.play();
    }
  };

  const onSubject = (subject_id = null) => {
    if (course) {
      setPage(
        parseInt(course.subject_set.findIndex((data) => data.id === parseInt(q_subject_id)) / show),
      );
    }
    if (q_subject_id !== subject_id) {
      props.history.push(`/course/${props.match.params.course_id}/viewer?subject_id=${subject_id}`);
    }
    let req_subject_id = subject_id || subject.id;
    setIsPlay(false);

    if (!_is_pending.current && _is_video_ready.current) {
      _is_pending.current = true;
      if (!is_v2_player) {
        _is_video_ready.current = false;
      }
      if (player.current && is_v2_player) {
        player.current.pause();
      }
      _is_pending.current = true;
      CourseModel.subjectDetail({
        subject_id: req_subject_id,
        is_v2_player: is_v2_player ? 1 : 0,
      }).then(({ code, subject }) => {
        if (_is_mount.current) {
          if (code === 200) {
            ReactPixel.trackCustom('강의 재생', {
              course_title: course?.title,
            });

            if (
              !!CommonStore.tmp?.last_subject_set?.find(
                (data) => data.course_id === parseInt(props.match.params.course_id),
              )
            ) {
              dispatch(
                set_tmp(
                  'last_subject_set',
                  CommonStore.tmp.last_subject_set.map((data) =>
                    data.course_id === parseInt(props.match.params.course_id)
                      ? {
                          ...data,
                          subject_id: subject.id,
                          update_date: moment().format('YYYY-MM-DD HH:mm:ss'),
                        }
                      : data,
                  ),
                ),
              );
            } else {
              if (CommonStore.tmp?.last_subject_set?.length) {
                dispatch(
                  set_tmp('last_subject_set', [
                    ...CommonStore.tmp.last_subject_set,
                    {
                      course_id: parseInt(props.match.params.course_id),
                      subject_id: subject.id,
                      update_date: moment().format('YYYY-MM-DD HH:mm:ss'),
                    },
                  ]),
                );
              } else {
                dispatch(
                  set_tmp('last_subject_set', [
                    {
                      course_id: parseInt(props.match.params.course_id),
                      subject_id: subject.id,
                      update_date: moment().format('YYYY-MM-DD HH:mm:ss'),
                    },
                  ]),
                );
              }
            }

            let is_v2_player = CommonStore.service.v2_player;
            setSubject(subject);
            cur_time.current = 0;
            if (!subject.hls) {
              is_v2_player = false;
            }
            setIsV2Player(is_v2_player);
            if (is_v2_player) {
              if (player.current) {
                player.current.src({
                  src: `${atob(subject.hls)}`,
                  type: 'application/x-mpegURL',
                });
                let cur_tracks = player.current.remoteTextTracks();
                for (let i = 0; i < cur_tracks.length; i++) {
                  player.current.removeRemoteTextTrack(cur_tracks[i]);
                }
              } else {
                const videoElement = video_ref.current;
                if (videoElement) {
                  player.current = videojs(
                    videoElement,
                    {
                      controls: true,
                      responsive: true,
                      fluid: true,
                      sources: [
                        {
                          src: `${atob(subject.hls)}`,
                          type: 'application/x-mpegURL',
                        },
                      ],
                    },
                    () => {
                      player.current.on('error', () => {
                        setIsV2Player(false);
                        setIsFailed(true);
                      });
                      player.current.on('canplaythrough', () => {
                        onBufferedEnd();
                      });
                      player.current.on('waiting', () => {
                        onBuffered();
                      });
                      setV2PlayerReady(true);
                    },
                  );
                }
              }
            }
            _is_pending.current = false;
            dispatch(
              set_header_type(HEADER_TYPE_PC_VIEWER, HEADER_TYPE_MOBILE_SUB, {
                no_footer: true,
                no_top_btn: true,
                title: subject?.title,
                no_channel_talk_button: true,
                no_search_btn: true,
              }),
            );
            dispatch(set_footer_type(0));
            props.ready();
          } else {
            window.location.replace('/');
          }
        }
      });
    }
  };

  const onEnded = () => {
    if (!subject.is_viewed) {
      CourseModel.subjectViewCheck({ subject_id: subject.id }).then(({ code }) => {
        if (_is_mount.current) {
          if (code === 200) {
            setSubject({ ...subject, is_viewed: true });
            setSubjectSet((prevState) =>
              prevState.map((item) =>
                item.id === subject.id ? { ...item, is_viewed: true } : item,
              ),
            );
          }
        }
      });
    }
    if (auto_play) {
      nextSubject();
    }
  };

  const prevSubject = () => {
    let cur_index = subject_set.findIndex((data) => data.id === subject.id);
    if (cur_index !== 0) {
      setPage(parseInt((cur_index - 1) / show));
      props.history.push(
        `/course/${props.match.params.course_id}/viewer?subject_id=${subject_set[cur_index - 1].id}`,
      );
    }
  };

  const nextSubject = () => {
    let cur_index = subject_set.findIndex((data) => data.id === subject.id);
    if (subject_set.length - 1 > cur_index) {
      setPage(parseInt((cur_index + 1) / show));
      props.history.push(
        `/course/${props.match.params.course_id}/viewer?subject_id=${subject_set[cur_index + 1].id}`,
      );
    }
  };

  const onProgress = (e) => {
    if (e.playedSeconds > 60 && !subject.is_time_check && !_time_check_pending.current) {
      CourseModel.subjectViewTimeCheck({
        subject_id: subject.id,
        second: parseInt(e.playedSeconds),
      }).then(({ code }) => {
        if (_is_mount.current) {
          if (code === 200) {
            setSubject({ ...subject, is_time_check: true });
            _time_check_pending.current = false;
          }
        }
      });
    }
  };

  const onSecChange = (change_type = 'next', change_time = 0) => {
    let control_bar_ele = document.querySelector('.vjs-has-started');
    let duration = 0;
    let current_time = 0;

    window.scrollTo(0, 0);

    if (player.current) {
      if (is_v2_player) {
        duration = player.current.duration();
        current_time = player.current.currentTime();
        control_bar_ele.classList.remove('vjs-playing');
      } else {
        duration = player.current.getDuration();
        current_time = player.current.getCurrentTime();
      }

      // 기존 재생구간 생성
      if (
        Math.abs(change_time - current_time) > 7 &&
        prev_cur_time !== null &&
        prev_subject !== null
      ) {
        tmp_cur_time.current = prev_cur_time;
        setVisibleBackToCurTime(true);
        setTimeout(() => {
          setVisibleBackToCurTime(false);
        }, 8000);
      }

      if (change_type === 'next') {
        if (duration - current_time < 10) {
          change_time = duration;
        } else {
          change_time = current_time + 10;
        }
      } else if (change_time === 0) {
        if (current_time < 10) {
          change_time = 0;
        } else {
          change_time = current_time - 10;
        }
      } else if (change_time >= duration) {
        change_time = duration - 10;
        setAutoPlay(false);
      }

      params.delete('question_time');

      if (is_v2_player) {
        player.current.currentTime(change_time);
        control_bar_ele.classList.add('vjs-playing');
      } else {
        player.current.seekTo(change_time);
      }
    }
  };

  const onBuffered = () => {
    time_out = setTimeout(() => {
      setIsBuffer(true);
    }, 3000);
  };

  const onBufferedEnd = () => {
    clearTimeout(time_out);
    setIsBuffer(false);
  };

  const resetSwiperHeight = () => {
    if (is_tablet_or_mobile && swiper_ref.current) {
      if (swiper_state?.activeIndex === 0) {
        swiper_ref.current.style.height = slide_1.current.scrollHeight + 'px';
      } else {
        swiper_ref.current.style.height = slide_2.current.scrollHeight + 'px';
      }
    }
  };

  const makeQnaContainer = () => {
    const getPlayedTime = () => {
      if (is_v2_player) {
        return player.current.currentTime();
      } else {
        return player.current.getCurrentTime();
      }
    };

    const getMaxDuration = () => {
      if (player.current) {
        if (is_v2_player) {
          return player.current.duration();
        } else {
          return player.current.getDuration();
        }
      } else {
        return 600;
      }
    };

    return (
      <QnaContainer
        course_id={props.match.params.course_id}
        subject_index={subject.index_name}
        subject_id={q_subject_id}
        visible_question_time_input={true}
        visible_top_row={!is_tablet_or_mobile}
        page_size={10}
        is_viewer_ver={true}
        getPlayedTime={getPlayedTime}
        getMaxDuration={getMaxDuration}
        resetSwiperHeight={resetSwiperHeight}
        onSecChange={onSecChange}
      />
    );
  };

  const makeSubjectList = () => {
    // @TODO 봄 강의 목록 챕터화
    // import {transSubject} from "./CourseDetail";
    // let chaptered_subject = transSubject(subject_set);

    return (
      <div className={'subject-wrapper' + (is_sticky ? ' sticky' : '')}>
        <div className='subject-wrapper-top'>
          <div className='subject-info-wrap'>
            <h5 className='total-video-count'>총 {subject_set.length}개 강의</h5>
            <h5 className='attendance_rate'>
              수강률 {(subject.attendance_rate * 100).toFixed(2)}%
            </h5>
          </div>
          <div className='autoplay-switch-wrap'>
            <label>연속재생</label>
            <input
              type='checkbox'
              checked={auto_play}
              onChange={() => {
                let new_auto_play = !auto_play;
                dispatch(set_tmp('viewer_auto_play', new_auto_play));
                setAutoPlay(new_auto_play);
              }}
            />
          </div>
        </div>
        <ul className='subject-list'>
          {subject_set.map((sub, index) => {
            if (parseInt(index / show) === page) {
              return (
                <li
                  key={sub.id}
                  className={sub.id === parseInt(q_subject_id) ? 'active' : ''}
                  onClick={() => {
                    if (
                      !_is_pending.current &&
                      _is_video_ready.current &&
                      parseInt(q_subject_id) !== sub.id
                    ) {
                      props.history.push(
                        `/course/${props.match.params.course_id}/viewer?subject_id=${sub.id}`,
                      );
                      window.scrollTo(0, 0);
                    }
                  }}
                >
                  <div className='subject-index'>{sub.index_name}</div>
                  {sub.id === parseInt(q_subject_id) ? (
                    <div className='current-playing-video-wrap'>
                      <img
                        src={`${process.env.REACT_APP_IMG_URL}/static/svg/viewer/play.svg`}
                        alt=''
                      />
                      <span>재생중</span>
                      {sub.is_viewed && (
                        <img
                          className='video-viewed-icon'
                          src={`${process.env.REACT_APP_IMG_URL}/static/svg/viewer/task_alt_active_white.svg`}
                          alt='재생 완료한 강의'
                        />
                      )}
                    </div>
                  ) : (
                    <>
                      <div className={'subject-title-wrap' + (sub.is_viewed ? ' is-viewed' : '')}>
                        <p>{sub.chapter_description}</p>
                        <h4>{sub.title}</h4>
                      </div>
                      {sub.is_viewed && (
                        <img
                          className='video-viewed-icon'
                          src={`${process.env.REACT_APP_IMG_URL}/static/svg/viewer/task_alt_active.svg`}
                          alt='재생 완료한 강의'
                        />
                      )}
                    </>
                  )}
                </li>
              );
            } else {
              return null;
            }
          })}
        </ul>
        <div className='subject-pagenation'>
          <span
            onClick={() => {
              if (block > 0) {
                setPage(block_size * (block - 1) + block_size - 1);
                setBlock(block - 1);
              }
            }}
            style={
              block > 0
                ? { opacity: 1, pointerEvents: 'inherit' }
                : {
                    opacity: 0.2,
                    pointerEvents: 'none',
                  }
            }
          >
            이전
          </span>
          {total_page.map((p) => {
            if (parseInt(p / block_size) === block) {
              return (
                <span
                  key={p}
                  onClick={() => {
                    setPage(p);
                  }}
                  className={page === p ? 'selected' : ''}
                >
                  {p + 1}
                </span>
              );
            } else {
              return null;
            }
          })}
          <span
            onClick={() => {
              if (block < total_block) {
                setPage(block_size * (block + 1));
                setBlock(block + 1);
              }
            }}
            style={
              block < total_block
                ? { opacity: 1, pointerEvents: 'inherit' }
                : {
                    opacity: 0.3,
                    pointerEvents: 'none',
                  }
            }
          >
            다음
          </span>
        </div>
      </div>
    );
  };

  return (
    <div className='viewer-container'>
      <Helmet>{subject && <title>{subject.title}</title>}</Helmet>
      <div
        className='fixed-area-line'
        style={{ width: '100%', height: 1 }}
        ref={subject_wrapper_ref}
      />

      <div className='td-wrapper viewer'>
        {subject ? (
          <div className='video-wrapper'>
            <div className='video-title-wrap'>
              <p>{course.title}</p>
              {course?.document_url && (
                <a href={course.document_url}>
                  비법노트
                  <img
                    src={`${process.env.REACT_APP_IMG_URL}/static/svg/viewer/ic-download.svg`}
                    alt='비법노트'
                  />
                </a>
              )}
            </div>
            <div className={'video' + (is_tablet_or_mobile && is_sticky ? ' sticky' : '')}>
              {is_buffer && (
                <div className='video-buffer-wrap'>
                  {!is_w550 ? (
                    <>
                      <h4>
                        <img
                          src={`${process.env.REACT_APP_IMG_URL}/static/svg/viewer/warning.svg`}
                          alt='영상 재생 문제 해결'
                        />
                        영상 재생에 문제가 있으신가요?
                      </h4>
                      <Link
                        className='video-solution-btn'
                        to={'/service/info/viewer'}
                        target={'_blank'}
                      >
                        문제 해결 방법 보기
                      </Link>
                    </>
                  ) : (
                    <Link
                      to={'/service/info/viewer'}
                      target={'_blank'}
                      className='mo-video-solution-btn'
                    >
                      <img
                        src={`${process.env.REACT_APP_IMG_URL}/static/svg/viewer/warning.svg`}
                        alt='영상 재생 문제 해결'
                      />
                      영상 문제 해결 방법 보기
                    </Link>
                  )}
                </div>
              )}
              <div className='video-action-wrapper'>
                {
                  !is_failed && (
                    <>
                      {!is_tablet_or_mobile ? (
                        <div
                          className={'video-action-btn prev' + (state.replay_hover ? ' hover' : '')}
                          onMouseEnter={() => {
                            if (is_play && !is_tablet_or_mobile && !is_landscape)
                              setState({
                                ...state,
                                replay_hover: true,
                              });
                          }}
                          onMouseLeave={() => {
                            if (is_play && !is_tablet_or_mobile && !is_landscape)
                              setState({
                                ...state,
                                replay_hover: false,
                              });
                          }}
                        >
                          <div
                            className={'action-btn'}
                            onClick={() => {
                              onSecChange('prev');
                            }}
                          />
                        </div>
                      ) : null}

                      {!is_tablet_or_mobile ? (
                        <div
                          className={
                            'video-action-btn next' + (state.forward_hover ? ' hover' : '')
                          }
                          onMouseEnter={() => {
                            if (is_play && !is_tablet_or_mobile && !is_landscape)
                              setState({
                                ...state,
                                forward_hover: true,
                              });
                          }}
                          onMouseLeave={() => {
                            if (is_play && !is_tablet_or_mobile && !is_landscape)
                              setState({
                                ...state,
                                forward_hover: false,
                              });
                          }}
                        >
                          <div className={'action-btn'} onClick={() => onSecChange('next')} />
                        </div>
                      ) : null}

                      <button
                        className={
                          'back-to-current-time-btn' + (visible_back_to_cur_time ? ' show' : '')
                        }
                        onClick={() => {
                          setVisibleBackToCurTime(false);
                          if (prev_subject !== null) {
                            props.history.replace(
                              `/course/${course.id}/viewer?subject_id=${prev_subject}&question_time=${tmp_cur_time.current}`,
                            );
                          } else {
                            if (is_v2_player) {
                              player.current.currentTime(tmp_cur_time.current);
                            } else {
                              player.current.seekTo(tmp_cur_time.current);
                            }
                          }
                        }}
                      >
                        <img
                          src={`${process.env.REACT_APP_IMG_URL}/static/v2/png/viewer/ic_playback.png`}
                          alt='기존 구간 되돌리기'
                        />
                        <p>기존 재생 구간으로 돌아가기</p>
                      </button>
                    </>
                  ) /*: (
                  <div className='video-load-failed'>
                    <h3>🚨 앗 문제가 발생했습니다!</h3>
                    <p>영상을 불러 올 수 없습니다. 관리자에게 문의해주세요 😭</p>
                  </div>
                )*/
                }
              </div>

              {is_v2_player ? (
                <div data-vjs-player>
                  <video
                    ref={video_ref}
                    className='video-js vjs-big-play-centered'
                    onPlay={() => {
                      setIsPlay(true);
                    }}
                    onKeyDown={(e) => {
                      onKeyDown(e);
                    }}
                  />
                </div>
              ) : (
                <ReactPlayer
                  ref={player}
                  playing={_is_playing}
                  // pause={_is_paused}
                  onBufferEnd={onBufferedEnd}
                  onBuffer={onBuffered}
                  onProgress={onProgress}
                  onReady={() => {
                    _is_video_ready.current = true;

                    if (question_time) {
                      setTimeout(() => {
                        onSecChange(null, question_time);
                      }, 50);
                    } else {
                      setVisibleBackToCurTime(false);
                    }
                    setIsFailed(false);
                  }}
                  controls={true}
                  height='100%'
                  width='100%'
                  playsinline={true}
                  onEnded={onEnded}
                  onStart={() => {
                    setIsPlay(true);
                  }}
                  onError={() => {
                    _is_video_ready.current = true;
                    setIsFailed(true);
                  }}
                  config={{
                    vimeo: {
                      playerOptions: {
                        autopause: false,
                        autoplay: auto_play,
                        responsive: true,
                        height: '100%',
                        width: '100%',
                      },
                    },
                  }}
                  url={`https://vimeo.com/${subject.vimeo_id}`}
                />
              )}
            </div>
            <div className='video-error-resolution'>
              <img
                src={`${process.env.REACT_APP_IMG_URL}/static/svg/viewer/help_outline_black.svg`}
                alt='영상 재생 문제 해결'
              />
              <a href='/service/info/viewer' rel='noreferrer' target='_blank'>
                영상 재생에 문제가 있으신가요?
              </a>
            </div>
            <div className='subject-info-wrapper'>
              <h3>
                {subject.index_name + '.'} {subject.title}
              </h3>
              <div
                className={`subject-description ${folded_description ? 'folded' : ''}`}
                ref={description_ref}
                dangerouslySetInnerHTML={{
                  __html: subject.description.replace(
                    new_exp_match,
                    '<a target="_blank" href="$1" style="color: #2f7abe">$1</a>',
                  ),
                }}
              />
              {is_tablet_or_mobile ? (
                <>
                  {/*설명 접기, 펼치기 start*/}
                  {description_ref.current?.children.length > 3 ||
                  description_ref.current?.scrollHeight > 72 ? (
                    <button
                      className='view-more-description'
                      onClick={() => {
                        setFoldedDescription(!folded_description);
                      }}
                    >
                      <img
                        src={`${process.env.REACT_APP_IMG_URL}/static/v2/png/common/ic_arrow_${
                          folded_description ? 'down' : 'up'
                        }.png`}
                        alt='설명 펼치기 아이콘'
                      />
                      <p>{folded_description ? '더 보기' : '접기'}</p>
                    </button>
                  ) : null}
                  {/*설명 접기, 펼치기 end*/}

                  {/*모바일 버전 시 비법노트 다운 버튼 start*/}
                  {course?.document_url && (
                    <a href={course.document_url} className='mo-secret-note-down'>
                      비법노트 다운로드
                      <img
                        src={`${process.env.REACT_APP_IMG_URL}/static/v2/png/common/ic_download_b.png`}
                        alt='비법노트'
                      />
                    </a>
                  )}
                  {/*모바일 버전 시 비법노트 다운 버튼 end*/}
                </>
              ) : null}
            </div>
            {!is_tablet_or_mobile ? makeQnaContainer() : null}
          </div>
        ) : null}

        {/*모바일 버전 시 qna, 수강 목록 셔플 start*/}
        {subject ? (
          <>
            {is_tablet_or_mobile ? (
              <>
                <div className={'shuffle-btn-wrap'}>
                  <button
                    className={active_slide_index === 0 ? 'selected' : null}
                    onClick={() => {
                      swiper_state.slideTo(0, 0);
                    }}
                  >
                    강의 목록
                  </button>
                  <button
                    className={active_slide_index === 1 ? 'selected' : null}
                    onClick={() => {
                      swiper_state.slideTo(1, 0);
                    }}
                  >
                    Q&A
                  </button>
                </div>
                <Swiper
                  ref={swiper_ref}
                  autoHeight={true}
                  threshold={30}
                  onSwiper={(swiper) => {
                    setSwiperState(swiper);
                    setActiveSlideIndex(0);
                  }}
                  onSlideChange={() => {
                    resetSwiperHeight();
                    setActiveSlideIndex(swiper_state.activeIndex);
                  }}
                >
                  <SwiperSlide className='slide-wrap' virtualIndex={1} ref={slide_1}>
                    {makeSubjectList()}
                  </SwiperSlide>
                  <SwiperSlide className='slide-wrap' virtualIndex={2} ref={slide_2}>
                    {makeQnaContainer()}
                  </SwiperSlide>
                </Swiper>
              </>
            ) : (
              <>{makeSubjectList()}</>
            )}
          </>
        ) : null}
        {/*모바일 버전 시 qna, 수강 목록 셔플 end*/}

        {subject && active_slide_index === 0 ? (
          <div className='bottom-viewer-btn'>
            <button
              className={
                subject_set.findIndex((data) => data.id === subject.id) === 0
                  ? 'viewer-prev-btn disabled'
                  : 'viewer-prev-btn'
              }
              onClick={() => {
                if (subject_set.findIndex((data) => data.id === subject.id) !== 0) {
                  if (!_is_pending.current && _is_video_ready.current) {
                    prevSubject();
                  }
                }
              }}
            >
              이전 강의
            </button>
            <button
              className={
                subject_set.findIndex((data) => data.id === subject.id) === subject_set.length - 1
                  ? 'viewer-next-btn disabled'
                  : 'viewer-next-btn active'
              }
              onClick={() => {
                if (!_is_pending.current && _is_video_ready.current) {
                  nextSubject();
                }
              }}
            >
              다음 강의
            </button>
          </div>
        ) : null}

        {is_tablet_or_mobile ? (
          <button
            className={
              'td-top-btn' +
              (is_sticky ? ' fade-in-right-anim' : ' fade-out-right-anim') +
              (active_slide_index === 1 ? ' qna' : '')
            }
            onClick={() => {
              window.scrollTo(0, 0);
            }}
          />
        ) : null}
      </div>
    </div>
  );
};

export default Viewer;
